import styled from "styled-components";
import InputLabel from "../shared/InputLabel";
import { useEffect } from "react";
import { breakpoints } from "styles/variables";
import Aos from "aos";
import "aos/dist/aos.css";

const BaseQuestions = () => {
  useEffect(() => {
    Aos.init({
      duration: 300,
      offset: 200,
      once: false,
    });
  }, []);

  return (
    <div
      data-aos="fade-right"
      data-aos-anchor-placement="center"
      data-aos-duration="500"
    >
      <InputLabel
        title={"Имя*"}
        placeholder={"Имя"}
        name={"name"}
        animation={false}
      />
      <Connection>
        <InputLabel
          title={"Ваш мобильный*"}
          placeholder={"+7 (  ) - -"}
          type={"phone"}
          name={"phone"}
          animation={false}
        />
        <InputLabel
          title={"Email*"}
          placeholder={"Email"}
          type={"email"}
          name={"email"}
          animation={false}
        />
      </Connection>
      <InputLabel
        title={"Название проекта"}
        placeholder={""}
        name={"projectName"}
        animation={false}
      />
    </div>
  );
};

const Connection = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`;

export const Title = styled.h2`
  margin-top: 64px;
  margin-bottom: 48px;

  font-family: "IBM Plex Mono", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: black;
  font-size: 44px;
  @media ${breakpoints.mobile} {
    font-size: 25px;
  }
`;

export default BaseQuestions;
