import { useFormContext, Controller } from "react-hook-form";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { InputWrapper } from "./InputLabel";
import styled from "styled-components";
import { breakpoints } from "styles/variables";

export default function CheckboxLabels({ labels, name, title }) {
  const { control, setValue, watch } = useFormContext();

  const selectedValues = watch(name) || [];

  const handleChange = (label) => {
    const updatedValues = selectedValues.includes(label) ? selectedValues.filter((value) => value !== label) : [...selectedValues, label];

    setValue(name, updatedValues);
  };

  return (
    <InputWrapper size={"100%"}>
      <p>{title}</p>
      <FormGroup>
        {labels.map((label) => (
          <Controller
            key={label}
            name={name}
            control={control}
            render={() => (
              <Label
                control={
                  <Checkbox
                    checked={selectedValues.includes(label)}
                    onChange={() => handleChange(label)}
                    value={label}
                  />
                }
                label={label}
              />
            )}
          />
        ))}
      </FormGroup>
    </InputWrapper>
  );
}

const Label = styled(FormControlLabel)`
  & .MuiTypography-root {
    font-family: "Mulish", sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: #181818;
    margin-left: 16px;
    @media ${breakpoints.mobile} {
      font-size: 15px;
    }
  }
  & .MuiButtonBase-root {
    padding: 0;
    margin-left: 8px;
  }
  & .MuiSvgIcon-root {
    width: 45px;
    height: 45px;
    fill: #cfd0d7;
    @media ${breakpoints.mobile} {
      width: 25px;
      height: 25px;
    }
  }
`;
