import { Chip } from "@mui/material";
import styled from "styled-components";
import { SELECT_SERVICES } from "shared/constants/brief";
import { useTranslation } from "react-i18next";

const Chips = ({ selectedServices, setSelectedServices }) => {
  const handleToggle = (service) => {
    if (selectedServices.includes(service)) {
      setSelectedServices(selectedServices.filter((item) => item !== service));
    } else {
      setSelectedServices([...selectedServices, service]);
    }
  };

  return (
    <ChipsWr>
      {SELECT_SERVICES.map((service) => (
        <StyledChip
          key={service}
          label={service}
          clickable
          onClick={() => handleToggle(service)}
          selected={selectedServices.includes(service)}
        />
      ))}
    </ChipsWr>
  );
};

const ChipsWr = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
`;

const StyledChip = styled(Chip)`
  && {
    background-color: ${(props) => (props.selected ? "#000" : "#f0f0f0")};
    color: ${(props) => (props.selected ? "#fff" : "#000")};
    border: ${(props) => (props.selected ? "none" : "1px solid #cfd0d7")};
    transition: background-color 0.3s ease, color 0.3s ease;
    &:hover {
      background-color: ${(props) => (props.selected ? "#333" : "#e0e0e0")};
    }
  }
`;

export default Chips;
