export const CHECKBOX_LABELS = [
  "Админ панель",
  "Перенос контента со старого сайта на новый",
  "SEO оптимизация",
  "Интеграция системы метрик",
];

export const SELECT_SERVICES = [
  "Web-разработка",
  "Мобильное приложение",
  "Настольная игра / Комикс",
  "Мобильная игра / AR",
  "2D и 3D графика",
];

export const MULTIPURPOSE_BRIEF = [
  [
    {
      pageTitle: "КОМПАНИЯ И РЫНОК",
      title: "Название вашей компании *",
      placeholder: "PushKeen",
      name: "companyName",
    },
    {
      title: "Расскажите, чем занимается ваша компания? *",
      placeholder: "Организация мероприятий",
      name: "fieldOfActivity",
    },
    {
      title: "Расскажите, какие продукты/услуги вы предлагаете клиентам? *",
      type: "area",
      name: "products",
    },
    {
      title:
        "Кто ваши клиенты? Расскажите о сегментах вашей аудитории. Какой их сегментов вы считаете ключевым? Если вы планируете охватывать новые сегменты аудитории, расскажите, какие * ",
      type: "area",
      name: "clients",
    },
    {
      title: "Кто ваши ключевые конкуренты? По возможности, прикрепите ссылки на их сайты и/или социальные сети *",
      type: "area",
      name: "competitors",
    },
    {
      title: "Какое преимущество выделяет вас среди конкурентов?",
      type: "area",
      name: "advantages",
    },
  ],
  [
    {
      pageTitle: "ОЖИДАНИЯ",
      title: "Какую задачу должен решать продукт/проект? *",
      type: "area",
      name: "tasks",
    },
    {
      title: "В какие сроки нужно завершить работу? *",
      type: "area",
      name: "deadlines",
    },
    {
      title: "Рассматриваете ли вы предложения от других компаний? Как вы будете выбирать партнёра? Когда вы планируете принять решение?",
      type: "area",
      name: "offers",
    },
  ],
];

export const WED_DEV_BRIEF = [
  {
    pageTitle: "УСЛУГА: ВЕБ-РАЗРАБОТКА",
    type: "file",
    name: "technicalSpecifications",
    title: "Если у вас есть документ с ТЗ или уже готовый прототип, прикрепите его здесь.",
  },
  {
    title: "Предполагаете ли вы создание одностраничного или многостраничного сайта?",
    name: "site",
  },
  {
    title: "Укажите предполагаемые разделы вашего будущего сайта.",
    type: "area",
    name: "sections",
  },
  {
    title: "Есть ли у вас качественные фото- или видео-материалы для размещения на сайте?",
    type: "area",
    name: "stuff",
  },
  {
    title: "Поделитесь ссылками на сайты, которые вам нравятся. Расскажите, почему они нравятся.",
    type: "area",
    name: "favSites",
  },
  {
    title: "Поделитесь ссылками на сайты, которые вам не нравятся. Почему они не нравятся? ",
    type: "area",
    name: "hateSites",
  },
  {
    title: "Укажите услуги, кроме разработки сайта, которые вам необходимы:",
    type: "checkbox",
    name: "features",
  },
  {
    title: "Здесь вы можете указать другие пожелания к сайту, о которых мы не спросили:",
    type: "area",
    name: "other",
  },
];
