import { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import MultipurposeQuestions from "./MultipurposeQuestions";
import Wrapper from "../../../shared/components/Wrapper";
import { nodemailerUrlBrief } from "../../../shared/constants/urls";
import axios from "axios";
import { breakpoints } from "styles/variables";
import { useMediaQuery } from "@mui/material";
import Chips from "../shared/Chips";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { baseSchema, webDevSchema } from "./Questions.schema";

import Aos from "aos";
import "aos/dist/aos.css";
import BaseQuestions from "./BaseQuestions";
import WebDevQuestions from "./WebDevQuestions";

const Questions = () => {
  const [selectedServices, setSelectedServices] = useState(["Web-разработка"]);
  console.log(selectedServices, "selectedServices");

  const validationSchema = useMemo(() => {
    let schema = baseSchema;
    if (selectedServices.includes("Web-разработка")) {
      schema = schema.merge(webDevSchema);
    }
    return schema;
  }, [selectedServices]);

  const methods = useForm({
    resolver: zodResolver(validationSchema),
    mode: "onBlur",
  });

  const { handleSubmit, formState } = methods;
  const { dirtyFields, isDirty, isValid } = formState;

  useEffect(() => {
    Aos.init({
      duration: 300,
      offset: 200,
      once: false,
    });
  }, []);

  const sendEmail = async (data) => {
    // const newData = { briefType: selectedServices, ...data };
    const newData = { ...data, features: data?.features.join(", ") };
    console.log(newData, "newData");
    const response = await axios.post(nodemailerUrlBrief, newData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("response: ", response);
    if (response.status === 200) {
      // e.target.reset();
      // setCurrentPage(0);
    }
  };

  const isMobile = useMediaQuery(breakpoints.mobile);

  return (
    <FormProvider {...methods}>
      <Wrapper>
        <div
          data-aos="fade-left"
          data-aos-anchor-placement="center"
          data-aos-duration="500"
        >
          <Title>Бриф на разработку сайта</Title>
        </div>

        <form onSubmit={handleSubmit(sendEmail)}>
          <BaseQuestions />
          {/* <Chips
            selectedServices={selectedServices}
            setSelectedServices={setSelectedServices}
          /> */}
          <MultipurposeQuestions />
          {selectedServices.find((el) => el === "Web-разработка") && <WebDevQuestions />}

          <ButtontsWr>
            <ButtonBlue type="submit">
              <h5>отправить</h5>
            </ButtonBlue>
          </ButtontsWr>
        </form>
      </Wrapper>
    </FormProvider>
  );
};

const Title = styled.h1`
  margin-top: 48px;
  margin-bottom: 64px;

  font-family: "IBM Plex Mono", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: black;
  font-size: 68px;

  @media ${breakpoints.mobile} {
    font-size: 38px;
  }
`;

const ButtonBlue = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1858fb;
  border-radius: 100px;
  color: white;

  h5 {
    font-family: "IBM Plex Mono", sans-serif;
    line-height: 52px;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 20px;
    margin: 0 100px;
    color: white;
    @media ${breakpoints.mobile} {
      font-size: 15px;
      margin: 0 4vw;
    }
  }

  h6 {
    font-family: "Inter", sans-serif;
    font-size: 35px;
    margin: 0 100px;
    @media ${breakpoints.mobile} {
      margin: 0 4vw;
      font-size: 15px;
    }
  }

  &:disabled {
    background-color: #9b9fb1;
    pointer-events: none;
  }

  &:focus {
    opacity: 1;
  }

  &:active {
    opacity: 0.8;
  }
`;
const ButtontsWr = styled.div`
  display: flex;
  justify-content: end;

  width: 100%;
  margin-bottom: 60px;
`;

export default Questions;
