import { useFormContext, Controller } from "react-hook-form";
import styled from "styled-components";
import svg from "../../../assets/svg/clipBreif.svg";
import { breakpoints } from "styles/variables";

const FileLoad = ({ title, fieldName }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={null}
      render={({ field }) => (
        <AttachFileWrapper>
          <label>
            <span>{title}</span>
            <input
              type={"file"}
              name={fieldName}
              onChange={(event) => {
                field.onChange(event.target.files[0]);
              }}
              hidden
            />
          </label>

          {field.value && (
            <DownloadFile id={"idDownloadFile"}>
              <span>Загруженный файл:</span>
              <li>{field.value.name}</li>
            </DownloadFile>
          )}
        </AttachFileWrapper>
      )}
    />
  );
};

const AttachFileWrapper = styled.div`
  cursor: pointer;
  margin-bottom: 40px;
  position: relative;
  width: 100%;
  border-radius: 24px;
  padding: 8px 35px;
  justify-self: center;
  align-items: center;
  transition: 1s;
  & > label {
    cursor: pointer;
  }
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  color: black;
  font-size: 24px;
  @media ${breakpoints.mobile} {
    font-size: 15px;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 28px;
    height: 28px;
    background: url(${svg});
    transition: 0.5s;
  }
  &:hover {
    &:before {
      transform: rotatez(90deg);
    }
  }
`;

const DownloadFile = styled.ul`
  font-family: "Mulish";
  //opacity: 0;
  //height: 0;
  font-weight: 400;
  color: black;
  margin-top: 8px;
  transition: 1s ease-in-out;

  & li {
    margin: 5px 10px;
    transition: 1s;
  }
  & span {
    font-size: 20px;
    line-height: 26px;
    transition: 1s;
  }
`;

export default FileLoad;
